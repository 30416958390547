<template>
    
    <div>
        {{users.title}}
      <ul>
        <li v-for="(product,index) in users.products" :key="index"> {{product.model}}</li>
    </ul>
    </div>
</template>

<script>
    export default {
        name: 'UsersList',
        props: {
            users: {
                type: Object,
                required: true
            }
        },
    };
</script>
