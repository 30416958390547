<template>
  <div>
    <router-link to="/">Go to Home page</router-link>
    <h1>About page</h1>
    <users-list :users="users"></users-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UsersList from "./components/users-list/index.vue";
export default {
  name: "About",
  metaInfo() {
    return {
      title: `${this.title} | HelloBB`,
      meta: [{ name: "description", content: this.title }]
    };
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      users: "users"
    }),
    title() {
      return this.users.title;
    }
  },
  components: {
    UsersList
  },
  // Server-side only
  // This will be called by the server renderer automatically
  serverPrefetch() {
    // return the Promise from the action
    // so that the component waits before rendering
    return this.getUsers();
  },

  // Client-side only
  mounted() {
    // If we didn't already do it on the server, we fetch the users
    if (!this.users.length) {
      this.getUsers();
    }
  },

  methods: {
    getUsers() {
      return this.$store.dispatch("getUsers");
    }
  }
};
</script>